<template lang="pug">
    ArticleWrapper
        h1 Guide for Students
        h2.oblique.text-center.text--secondary.mt-4 How to use SeriCity to quickly level up your English
        v-divider.my-12
        h3.text-uppercase What is SeriCity?
        p SeriCity is a collection of interactive lessons for teachers and A2 → C2 EFL students, based on popular Netflix TV shows.
        ul
            li Teachers get ready-made lesson plans, including interesting discussion questions to use during remote EFL lessons. 
            li Students get lists of <em>Key Expressions</em> from each episode, with meanings and examples, and interactive games to help learn and memorize the content.

        h3.text-uppercase How to Prepare for a Lesson
        v-row.flex-column-reverse.flex-md-row.mb-4
            v-col(cols="12" md="8")
                p If your English level is B1 or B2, you will need to watch each episode multiple times to get the most value from your lesson.
                .d-flex.grey.lighten-3.align-start.my-6.pa-3
                    v-icon.mt-2.mr-3 mdi-information
                    | Before you start watching, review the student lesson link your teacher shared with you.
                p Concentrate on the <b>Key Expressions</b> section. These are natural English expressions from the episode, in the same order as they appear in the show.
                p Take time to review each expression's meaning and example. Listen to the pronunciation of each key expression. You can play interactive games to practice these expressions.
                p After you are famliar with the key expressions, then you are ready to start watching.
            
            v-col(cols="12" md="4")
                v-img(src="@/assets/images/watch.svg" cover)

        v-card(flat :color="'grey lighten-' + (idx + 3)" v-for="step, idx in watchSteps", :key="step.label").pa-5.mb-3
            v-row
                v-col(cols="2")
                    .step.d-flex.align-end.mb-3.flex-column.flex-md-row.justify-center.justify-md-end
                        v-icon.mr-2.mb-1(v-if="$vuetify.breakpoint.mdAndUp") mdi-television
                        .label.text--disabled.mr-1 Step
                        .idx.display-2 {{idx + 1}}
                v-col(cols="10")
                    .text-h5.font-weight-bold.mb-2 {{step.title}}
                    v-divider.my-4
                    p(v-for="bodyStep in step.body", :key="bodyStep") {{bodyStep}}

        
        v-card(flat color="grey lighten-2" tile).pa-5
          v-row
            v-col(cols="12" md="3").text-center
              h3.my-0 After Watching
            v-col(cols="12" md="9")
              p After watching and before your lesson, you might also want to review the discussion questions we've included in your lesson plan. Your teacher will be asking you these questions.

</template>

<script>
import { get } from "vuex-pathify";
import ArticleWrapper from "@/components/Articles/ArticleWrapper.vue";
export default {
  name: "StudentGuide",
  components: { ArticleWrapper },
  data() {
    return {
      watchSteps: [
        {
          label: "First time",
          title: "Watch with subtitles in your own language",
          body: [
            "The goal is for you to understand the plot and character motivations. This helps you listen and put things in context later.",
            "For example, if you are a native Russian speaker, watch the episode with English audio and Russian subtitles.",
          ],
        },
        {
          label: "Second time",
          title: "Watch with subtitles in English",
          body: [
            "Next watch the episode with English subtitles.",
            "Remember that Netflix lets you control the playback speed, so you might want to watch at 0.75x speed",
            "Concentrate on listening for the key expressions from the lesson, and trying to understand them in the context of the episode.",
          ],
        },
        {
          label: "Third time",
          title: "Watch with no subtitles",
          body: [
            "This time listen for the key expressions without the help of subtitles",
            "Pause the episode often, whenever you hear a key expression that seems interesting.",
            "Listen to the characters use the expression in context, and repeat after them, while trying to match their pronunciation, intonation, and emotion!",
          ],
        },
      ],
    };
  },
  computed: {
    ...get({ userData: "User/userData" }),
  },
};
</script>

<style lang="scss" scoped>
h2,
h3 {
  margin-bottom: 2rem;
}
</style>
