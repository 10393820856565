<template lang="pug">
    article.article-wrapper#article
        v-card.pa-6.pa-md-12(:color="color",flat, min-height="80vh")
            slot
</template>

<script>
export default {
  name: "ArticleWrapper",
  props: {
    color: { type: String, default: null },
  },
};
</script>

<style lang="scss">
#article {
  h1 {
    text-align: center;
  }
  h2,
  h3 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  @media only screen and (min-width: 600px) {
    h2,
    h3 {
      text-align: unset;
    }
  }
}
</style>
